(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/hero/views/hero-loader.js') >= 0) return;  svs.modules.push('/components/lb-ui/hero/views/hero-loader.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.lb_ui=_svs.lb_ui||{};
_svs.lb_ui.hero=_svs.lb_ui.hero||{};
_svs.lb_ui.hero.templates=_svs.lb_ui.hero.templates||{};
svs.lb_ui.hero.templates.hero_loader = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<div class=\"hero-loader\">\n  <svg class=\"hero-loader-spinner\">\n    <circle class=\"hero-loader-path\"></circle>\n  </svg>\n  <div class=\"hero-loader-left\"></div>\n  <div class=\"hero-loader-right\"></div>\n</div>\n";
},"useData":true});
Handlebars.partials['components-lb_ui-hero-hero_loader'] = svs.lb_ui.hero.templates.hero_loader;
})(svs, Handlebars);


 })(window);