(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/hero/views/hero-image.js') >= 0) return;  svs.modules.push('/components/lb-ui/hero/views/hero-image.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.lb_ui=_svs.lb_ui||{};
_svs.lb_ui.hero=_svs.lb_ui.hero||{};
_svs.lb_ui.hero.templates=_svs.lb_ui.hero.templates||{};
svs.lb_ui.hero.templates.hero_image = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"components-content-image_v2-image"),(depth0 != null ? lookupProperty(depth0,"image") : depth0),{"name":"components-content-image_v2-image","hash":{"cssSelectors":"imagev2-legacy hero-image"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    return "<div class=\"hero-image\"></div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"image") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":5,"column":7}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true});
Handlebars.partials['components-lb_ui-hero-hero_image'] = svs.lb_ui.hero.templates.hero_image;
})(svs, Handlebars);


 })(window);