(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/hero/views/hero-tab.js') >= 0) return;  svs.modules.push('/components/lb-ui/hero/views/hero-tab.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.lb_ui=_svs.lb_ui||{};
_svs.lb_ui.hero=_svs.lb_ui.hero||{};
_svs.lb_ui.hero.templates=_svs.lb_ui.hero.templates||{};
svs.lb_ui.hero.templates.hero_tab = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"hero-tab hero-icon\">\n  "
    + container.escapeExpression((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"icon",{"name":"ui","hash":{"icon":(depth0 != null ? lookupProperty(depth0,"tab") : depth0),"size":"400","color":"fc-white"},"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":2,"column":52}}}))
    + "\n</div>\n";
},"useData":true});
Handlebars.partials['components-lb_ui-hero-hero_tab'] = svs.lb_ui.hero.templates.hero_tab;
})(svs, Handlebars);


 })(window);